export const validate = ({
  base64File,
  signedBy,
  dispensedBy,
  relationship,
  reason,
  requiresSignaturePad,
}) => {
  const CANT_BE_BLANK = "can't be blank"
  const errors: {
    base64File?: string
    signedBy?: string
    dispensedBy?: string
    relationship?: string
    reason?: string
  } = {}

  if (requiresSignaturePad && !base64File) errors.base64File = CANT_BE_BLANK
  if (!signedBy) errors.signedBy = CANT_BE_BLANK
  if (!dispensedBy) errors.dispensedBy = CANT_BE_BLANK
  if (!relationship) errors.relationship = CANT_BE_BLANK
  if (relationship !== "self" && !reason) errors.reason = CANT_BE_BLANK

  return errors
}

import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"

import * as styles from "./index.module.scss"

interface Props {
  dashboardUrl: string
}

export const SupplierIntakeNavbar = ({ dashboardUrl }: Props) => {
  return (
    <nav className={styles.supplierIntakeNavbar}>
      <CanopyButton
        as="a"
        href={dashboardUrl}
        iconStart="arrow-left"
        size="small"
        variant="tertiary"
      >
        Return to dashboard
      </CanopyButton>
    </nav>
  )
}

// @ts-strict-ignore
import React from "react"
import CategorySelectorMobile from "./CategorySelectorMobile"
import { Props } from "./sharedTypes"
import CategorySelectorDesktop from "./CategorySelectorDesktop"

const CategorySelector: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <CategorySelectorDesktop {...props} />
      <CategorySelectorMobile {...props} header="Category" />
    </div>
  )
}

export default CategorySelector

// @ts-strict-ignore
import React from "react"
import { Option, NestedOptions } from "./sharedTypes"
import { Nullable } from "sharedTypes"

export type Props = {
  labelText: string
  options: NestedOptions[]
  selectedOption: Nullable<Option>
  select(n: number): void
}

const Select: React.FunctionComponent<Props> = ({
  options,
  selectedOption,
  labelText,
  select,
}) => {
  const name = `select-${labelText}`
  return (
    <>
      <label htmlFor={name}>{`Filter by ${labelText}`}</label>
      <select
        id={name}
        className="form-control"
        value={selectedOption && options.indexOf(selectedOption)}
        onChange={(event) => select(options[event.target.value].value)}
      >
        {options.map((option, idx) => (
          <option key={option.value} disabled={option.disabled} value={idx}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  )
}

export default Select

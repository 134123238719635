import React from "react"
import FocusTile from "./FocusTile"
import * as styles from "./FocusTiles.module.scss"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { createdBySupplierSubpageUrl } from "./urls"

type Props = {
  clinicalFacilityId: string
}

const FocusTiles: React.FC<Props> = ({ clinicalFacilityId }: Props) => {
  const { getFlagValue } = useFeatureFlags()
  const focusTilesFeatureFlagValue = getFlagValue("facilityDashboardFocusTiles")
  const createdBySupplierSubpageTileEnabled =
    focusTilesFeatureFlagValue === "subpage"

  if (getFlagValue("facilityDashboardFocusTiles") === null) return null

  function getCount(): Promise<{ count: number }> {
    return new Promise((resolve) => {
      // Simulate an asynchronous operation
      setTimeout(() => {
        resolve({ count: 0 })
      }, 1000) // Resolve after 1 second
    })
  }

  return (
    <div className={styles.flexContainer}>
      {createdBySupplierSubpageTileEnabled && (
        <FocusTile
          getCount={getCount}
          title="Incoming orders"
          subtitle="Orders sent from suppliers that require more information."
          url={createdBySupplierSubpageUrl(clinicalFacilityId)}
        />
      )}
    </div>
  )
}

export default FocusTiles

import React from "react"
import { PageInfo } from "../../../../../../graphql/__generated__/graphql"
import { Grid } from "@material-ui/core"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { canopyColorInteractiveLinkDefault } from "@parachutehealth/canopy-tokens-color"

interface Props {
  currentCount: number
  totalCount: number
  pageInfo: PageInfo
  fetchMore: any
}

const DmeOrdersTableFooter: React.FC<Props> = ({
  currentCount,
  totalCount,
  pageInfo,
  fetchMore,
}) => {
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      className="canopy-typography-body-small"
    >
      <Grid item>
        Displaying {currentCount} of {totalCount}
      </Grid>
      {pageInfo.hasNextPage && (
        <Grid>
          <CanopyButton
            variant="tertiary"
            onClick={() =>
              void fetchMore({ variables: { after: pageInfo.endCursor } })
            }
            style={{ color: canopyColorInteractiveLinkDefault }}
          >
            Load more
          </CanopyButton>
        </Grid>
      )}
      {!pageInfo?.hasNextPage && (
        <Grid item className="italic">
          Orders placed more than 90 days ago will not appear.
        </Grid>
      )}
    </Grid>
  )
}

export default DmeOrdersTableFooter

import { PackageSku } from "../../sharedTypes"
import {
  CatalogBrowseTab,
  CatalogSearchTab,
  CatalogSearchType,
} from "sharedTypes"
import React from "react"
import HybridSearch from "../HybridSearch"
import SingleSearch from "../SingleSearch"

interface Props {
  canFilterByFormulary: boolean
  catalogSearchType: CatalogSearchType
  combinedCatalogSearch: boolean
  filterQuery: string
  formularyPriceEnabled: boolean
  supplierIdParam: string
  selectedTab: CatalogSearchTab
  currentCatalogBrowseTab: CatalogBrowseTab
  setSelectedTab(tab: CatalogSearchTab): void
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectPackageWithSku(s: PackageSku): void
  updateFilterQuery(query: string): void
}

const ProductSearch: React.FC<Props> = (props) => {
  const { combinedCatalogSearch } = props

  if (combinedCatalogSearch) {
    return <HybridSearch {...props} />
  } else {
    return <SingleSearch {...props} />
  }
}

export default ProductSearch

import React from "react"
import { datadogRum } from "@datadog/browser-rum"
import { PackageWithCartData } from "../sharedTypes"
import GridItem from "./GridItem"
import { CatalogBrowseTab, DmeOrder } from "sharedTypes"

type Props = {
  currentCatalogBrowseTab: CatalogBrowseTab
  dmeOrder: DmeOrder
  onSelect(p: PackageWithCartData): Promise<void>
  packages: PackageWithCartData[]
  yourOrganizationsSuppliersOnly: boolean
}

const Grid = ({
  currentCatalogBrowseTab,
  dmeOrder,
  packages,
  onSelect,
  yourOrganizationsSuppliersOnly,
}: Props) => {
  return (
    <div className="grid row gutter-0 display-flex">
      {packages.map((pkg, index) => (
        <GridItem
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          dmeOrder={dmeOrder}
          key={pkg.externalId}
          onSelect={async (pkg): Promise<void> => {
            datadogRum.addAction(`click on Result #${index + 1}`, {
              packageName: pkg.name,
            })
            onSelect(pkg)
          }}
          pkg={pkg}
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      ))}
    </div>
  )
}

export default Grid

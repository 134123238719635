import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"

import * as styles from "./index.module.scss"
import { post } from "services/api"
import { navigate, reload } from "utilities/navigation"
import { createOrderUrl, orderUrl } from "../../../urls"
import { useCsrInboxState } from "../../../csrInboxStateContext"

export const ActionsFooter = () => {
  const csrInboxState = useCsrInboxState()
  const [saving, setSaving] = useState(false)
  const onClick = () => {
    setSaving(true)
    post(
      createOrderUrl(csrInboxState.supplierOrganization.id, csrInboxState.id)
    )
      .then((response) => {
        setSaving(false)
        navigate(orderUrl(csrInboxState.supplier.id, response["data"]["id"]))
      })
      .catch(() => {
        setSaving(false)
        reload()
      })
  }

  return (
    <div className={styles.actionsFooter}>
      <CanopyButton loading={saving} onClick={onClick}>
        Save and go to order
      </CanopyButton>
    </div>
  )
}

export default ActionsFooter

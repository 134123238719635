import React from "react"
import {
  DmeOrderWarning,
  SignatureWarningMetadata,
  DmeOrder,
  SignatureStatus,
} from "sharedTypes"
import { Permissions } from "../../sharedTypes"
import Warning from "./Warning"
import { handleError, reportError } from "utilities/error"
import SignaturePendingWarning from "./SignaturePendingWarning"
import SendSignatureRequestButton from "../SendSignatureRequestButton"
import * as api from "../../api"
import { navigate } from "../../../../../../utilities/navigation"
import { workflowReviewUrl } from "../../../../urls"
import { useFeatureFlags } from "components/FeatureFlagContext"

type Props = {
  warning: DmeOrderWarning
  trackEvents: boolean
  permissions: Permissions
  dmeOrder: DmeOrder
  sendSignatureRequest: (params: {}) => Promise<void>
}

const render = (
  warning: DmeOrderWarning,
  title: string,
  trackEvents: boolean,
  children: React.ReactNode = null
) => {
  return (
    <Warning
      warningType={warning.key}
      severity={warning.severity}
      title={title}
      text={warning.text}
      trackEvents={trackEvents}
    >
      {children}
    </Warning>
  )
}

const REVIEW_SIGNATURE = "Review Signature"
const SIGNATURE_NEEDED = "Signature Needed"

const SignatureWarning = ({
  warning,
  trackEvents,
  permissions,
  dmeOrder,
  sendSignatureRequest,
}: Props) => {
  const {
    doctor,
    signatureRequests,
    type,
    nextExpectedDate,
  } = warning.metadata as SignatureWarningMetadata
  const { isFeatureEnabled } = useFeatureFlags()

  const updateSignatureStatus = async (): Promise<void> => {
    return api
      .updateSignatureStatus()
      .then(() => {
        navigate(workflowReviewUrl())
      })
      .catch((error) => {
        return handleError(error, error.response?.data?.error)
      })
  }

  switch (type) {
    case "pending":
      return (
        <SignaturePendingWarning
          doctor={doctor}
          signatureRequests={signatureRequests}
          warningType={warning.key}
          trackEvents={trackEvents}
          nextExpectedDate={nextExpectedDate}
          permissions={permissions}
          dmeOrder={dmeOrder}
          sendSignatureRequest={sendSignatureRequest}
        />
      )
    case "manual":
      return render(warning, REVIEW_SIGNATURE, trackEvents)
    case "needed":
    case "invalid":
      const children =
        isFeatureEnabled("facilityFreedomProminentRejection") &&
        permissions.sendSignatureRequest &&
        dmeOrder.signatureStatus !== SignatureStatus.Pending &&
        dmeOrder.signatureStatus !== SignatureStatus.Completed ? (
          <SendSignatureRequestButton
            className="btn btn-brand-o btn-block"
            dmeOrder={dmeOrder}
            onClick={updateSignatureStatus}
            renderButtonAsLink={true}
          />
        ) : null
      return render(warning, SIGNATURE_NEEDED, trackEvents, children)
    default:
      reportError(new Error(`Unknown supplier signature type: ${type}`))
      return render(warning, REVIEW_SIGNATURE, trackEvents)
  }
}

export default SignatureWarning

import { Location } from "history"
import * as routes from "../routes"
import { isStepValid } from "./validation"
import {
  CLINICAL_STEP,
  DELIVERY_STEP,
  isSimpleFacilitySteps,
  ORDERED_STEPS,
  PAYMENT_STEP,
  PRODUCTS_STEP,
  REVIEW_STEP,
  SIGNATURE_STEP,
  StepContext,
  StepId,
  stepIdToStepTitle,
} from "./steps"
import {
  DmeOrder,
  DmeOrderWarning,
  EmployerType,
  OrderStatus,
  OrderType,
  SignatureStatus,
} from "sharedTypes"
import { requestedDocumentationRequirements } from "./documentationRequirements"
import {
  getNextFillablePdfOrChartNotePagePathWhenOnClinicalStep,
  THERE_IS_NO_NEXT_PATH_THIS_MIGHT_BE_BAD_BEHAVIOR,
} from "../containers/ClinicalFacilityClinical/navigation"

type Page = {
  id: StepId
  title: string
  active: boolean
  checked: boolean
}

type Step = {
  id: StepId
  title: string
}

const pageRoutes: Record<StepId, string> = {
  [PAYMENT_STEP]: routes.paymentPath(),
  [PRODUCTS_STEP]: routes.productsPath(),
  [DELIVERY_STEP]: routes.deliveryPath(),
  [CLINICAL_STEP]: routes.clinicalPath(),
  [SIGNATURE_STEP]: routes.signaturePath(),
  [REVIEW_STEP]: routes.reviewPath(),
}

const isActive = (location, path: string): boolean =>
  location.pathname.startsWith(`/${path}`)

const isChecked = (
  step: Step,
  warnings: DmeOrderWarning[],
  context: StepContext,
  calculateStepValidityWithDependents: boolean
) => {
  if (step.id === StepId.REVIEW_STEP) {
    return false
  }

  return isStepValid(
    step.id,
    warnings,
    context,
    calculateStepValidityWithDependents
  )
}

const buildPage = (
  context: StepContext,
  warnings: DmeOrderWarning[],
  location: Location,
  step: Step,
  calculateStepValidityWithDependents: boolean
): Page => ({
  ...step,
  active: isActive(location, step.id),
  checked: isChecked(
    step,
    warnings,
    context,
    calculateStepValidityWithDependents
  ),
})

const hideSteps = (dmeOrder: DmeOrder, context: StepContext): boolean => {
  const { documentationRequirements, lineItemGroups, orderTypes } = dmeOrder
  if (!isSimpleFacilitySteps(context)) {
    return false
  }
  if (
    requestedDocumentationRequirements(documentationRequirements).length > 0
  ) {
    return false
  }
  if (orderTypes.includes(OrderType.HospiceOrder)) {
    return true
  }

  return lineItemGroups.length > 0
}

const ALL_STEPS: Step[] = ORDERED_STEPS.map((stepId) => ({
  id: stepId,
  title: stepIdToStepTitle(stepId),
}))

const CONDITIONAL_STEPS: StepId[] = [CLINICAL_STEP, SIGNATURE_STEP]

export const getPages = (
  dmeOrder: DmeOrder,
  context: StepContext,
  warnings: DmeOrderWarning[],
  location: Location
): Page[] => {
  context.isOrderTransferred = dmeOrder.everTransferred
  const steps = dmeOrder.permissions.update ? ALL_STEPS : []
  const skipSteps = hideSteps(dmeOrder, context)
  const calculateStepValidityWithDependents =
    !dmeOrder.everTransferred ||
    ![SignatureStatus.Completed, SignatureStatus.NotNeeded].includes(
      dmeOrder.signatureStatus
    )

  return steps
    .map((step) =>
      buildPage(
        context,
        warnings,
        location,
        step,
        calculateStepValidityWithDependents
      )
    )
    .filter(
      (step) =>
        !skipSteps ||
        !CONDITIONAL_STEPS.includes(step.id) ||
        !isStepValid(step.id, warnings, context, false)
    )
}

export const nextPagePath = (
  dmeOrder: DmeOrder,
  context: StepContext,
  warnings: DmeOrderWarning[],
  location: Location
): string | undefined => {
  const pages = getPages(dmeOrder, context, warnings, location)
  const activePageIndex = pages.findIndex((page: Page) => page.active)

  if (activePageIndex < 0) {
    return
  }

  const nested =
    location.pathname !== `/${pages[activePageIndex].id}` &&
    pages[activePageIndex].id !== "products"

  if (pages[activePageIndex].id === "clinical") {
    const nextPaths = getNextFillablePdfOrChartNotePagePathWhenOnClinicalStep({
      updateChartNotesPermission: dmeOrder.permissions.updateChartNotes,
      documentationRequirements: dmeOrder.documentationRequirements,
      currentPath: location.pathname,
      chartNotes: dmeOrder.chartNotes,
    })
    if (nextPaths) {
      return nextPaths
    }
    if (nextPaths === THERE_IS_NO_NEXT_PATH_THIS_MIGHT_BE_BAD_BEHAVIOR) {
      return
    }
  } else if (pages[activePageIndex].id === "review") {
    return
  } else if (nested) {
    return stepPath(pages[activePageIndex].id)
  }

  return stepPath(pages[activePageIndex + 1].id)
}

export const defaultPath = routes.reviewPath()
export const stepPath = (step: string): string | undefined => pageRoutes[step]

export const defaultRedirectPath = (
  dmeOrder: DmeOrder,
  context: StepContext,
  warnings: DmeOrderWarning[],
  location: Location
): string => {
  const pages = getPages(dmeOrder, context, warnings, location)
  const productsStep = pages.find((page: Page) => page.id === PRODUCTS_STEP)
  const productsStepIsIncomplete = productsStep && !productsStep.checked
  const orderHasUnmatchedPackages =
    dmeOrder.mostRecentSupplierTransfer &&
    dmeOrder.mostRecentSupplierTransfer.unmatchedLineItemGroups.length > 0

  const orderNotCompleted =
    dmeOrder.orderStatus !== OrderStatus.Accepted &&
    dmeOrder.orderStatus !== OrderStatus.Canceled

  const supplierNeedsToCheckCartForUnmatchedPackages =
    productsStepIsIncomplete || (orderHasUnmatchedPackages && orderNotCompleted)

  if (context.currentEmployer.employerType !== EmployerType.ClinicalFacility) {
    if (
      dmeOrder.everTransferred &&
      dmeOrder.permissions.update &&
      supplierNeedsToCheckCartForUnmatchedPackages
    ) {
      return routes.productsPath()
    } else {
      return defaultPath
    }
  }
  const quickOrderPrompt =
    dmeOrder.permissions.sendDocuments && !dmeOrder.documentIds.length
  if (quickOrderPrompt) {
    return routes.quickOrderPath()
  }

  const firstIncompleteStep = pages.find((step: Page) => !step.checked)
  return (
    (firstIncompleteStep && stepPath(firstIncompleteStep.id)) || defaultPath
  )
}

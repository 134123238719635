import React from "react"
import {
  DmeOrder,
  Nullable,
  ConsignmentCloset,
  CatalogBrowseTab,
} from "sharedTypes"
import SidebarAwareContainer from "../SideBarAwareContainer"
import { NestedOptions } from "../CategorySelector/sharedTypes"
import { useFeatureFlags } from "components/FeatureFlagContext"
import MobileFilters from "./MobileFilters"
import Filters from "./Filters"

interface Props {
  dmeOrder: DmeOrder
  consignmentClosetOnly: boolean
  toggleConsignmentClosetFilter(): void
  defaultConsignmentClosetId: string
  consignmentClosets: ConsignmentCloset[]
  selectDefaultConsignmentCloset(id: string): Promise<void>

  formularyOnly: boolean
  canFilterByFormulary: boolean
  toggleFormularyFilter(): void

  canFilterByYourOrganizationsSuppliers: boolean
  yourOrganizationsSuppliersOnly: boolean
  toggleYourOrganizationsSuppliersFilter(): void

  categoryOptions: NestedOptions[]
  selectedCategory: Nullable<number>
  selectCategory(n: number): void
  currentCatalogBrowseTab: CatalogBrowseTab
  combinedCatalogSearch: boolean
  selectState: (state: string) => void
  serviceAreaState: string
}

const ProductFilters: React.FC<Props> = ({
  dmeOrder,
  consignmentClosetOnly,
  toggleConsignmentClosetFilter,
  defaultConsignmentClosetId,
  consignmentClosets,
  selectDefaultConsignmentCloset,
  formularyOnly,
  canFilterByFormulary,
  toggleFormularyFilter,
  canFilterByYourOrganizationsSuppliers,
  yourOrganizationsSuppliersOnly,
  toggleYourOrganizationsSuppliersFilter,
  categoryOptions,
  selectedCategory,
  selectCategory,
  currentCatalogBrowseTab,
  combinedCatalogSearch,
  selectState,
  serviceAreaState,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const { manageFulfillmentAgreements } = dmeOrder.permissions
  const { usesEnterpriseFeatures } = dmeOrder.clinicalFacility

  return (
    <>
      <SidebarAwareContainer role="filters">
        <Filters
          canFilterByFormulary={canFilterByFormulary}
          canFilterByYourOrganizationsSuppliers={
            canFilterByYourOrganizationsSuppliers
          }
          categoryOptions={categoryOptions}
          combinedCatalogSearch={combinedCatalogSearch}
          consignmentClosetOnly={consignmentClosetOnly}
          consignmentClosets={consignmentClosets}
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          defaultConsignmentClosetId={defaultConsignmentClosetId}
          formularyOnly={formularyOnly}
          manageFulfillmentAgreements={manageFulfillmentAgreements}
          selectCategory={selectCategory}
          selectedCategory={selectedCategory}
          selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
          selectState={selectState}
          serviceAreaState={serviceAreaState}
          toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
          toggleYourOrganizationsSuppliersFilter={
            toggleYourOrganizationsSuppliersFilter
          }
          toggleFormularyFilter={toggleFormularyFilter}
          usesEnterpriseFeatures={usesEnterpriseFeatures}
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      </SidebarAwareContainer>
      {isFeatureEnabled("userActivationMobileFilters") && (
        <SidebarAwareContainer role="mobileFilters">
          <MobileFilters
            canFilterByFormulary={canFilterByFormulary}
            canFilterByYourOrganizationsSuppliers={
              canFilterByYourOrganizationsSuppliers
            }
            categoryOptions={categoryOptions}
            combinedCatalogSearch={combinedCatalogSearch}
            consignmentClosetOnly={consignmentClosetOnly}
            consignmentClosets={consignmentClosets}
            currentCatalogBrowseTab={currentCatalogBrowseTab}
            defaultConsignmentClosetId={defaultConsignmentClosetId}
            formularyOnly={formularyOnly}
            manageFulfillmentAgreements={manageFulfillmentAgreements}
            selectCategory={selectCategory}
            selectedCategory={selectedCategory}
            selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
            selectState={selectState}
            serviceAreaState={serviceAreaState}
            toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
            toggleYourOrganizationsSuppliersFilter={
              toggleYourOrganizationsSuppliersFilter
            }
            toggleFormularyFilter={toggleFormularyFilter}
            usesEnterpriseFeatures={usesEnterpriseFeatures}
            yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
          />
        </SidebarAwareContainer>
      )}
    </>
  )
}

export default ProductFilters

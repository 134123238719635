import React from "react"
import {
  AsyncSingleSelectionField,
  AsyncSingleSelect,
  FieldType,
} from "../Fields"
import { makeApi } from "applications/FacilitySelectForm/api"
import { patch } from "services/api"
import { FacilityOption } from "sharedTypes"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"

export type InboxStateClinicalFacility = {
  facility?: {
    id: string
    name: string
  }
  extractedName?: string
}

type SubsetOfClinicalFacility = Pick<FacilityOption, "id" | "name">
export type FetchFacilities = (
  searchTerm: string
) => Promise<SubsetOfClinicalFacility[]>

type UpdateFacilityInformation = (
  updatedData: Partial<InboxStateClinicalFacility>
) => Promise<void>

export const updateFacilityInformation = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): UpdateFacilityInformation => async (
  updatedData: Partial<InboxStateClinicalFacility>
): Promise<void> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`
  const requestBody = {
    facility_id: updatedData.facility?.id || null,
  }
  await patch(`${pathToCsrInboxState}/update_clinical_facility`, requestBody)
}

export const FacilityData = ({
  facility,
}: {
  facility: InboxStateClinicalFacility
}) => {
  const csrInboxState = useCsrInboxState()
  const fetchFacilities = makeApi("Supplier", csrInboxState.supplier.id)
    .fetchFacilities

  const onChange = updateFacilityInformation({
    supplierOrganizationId: csrInboxState.supplierOrganization.id,
    csrInboxStateId: csrInboxState.id,
  })

  const onFacilitySelection = (
    facility: InboxStateClinicalFacility["facility"] | null
  ) => {
    return onChange({ facility: facility || undefined })
  }

  const field: AsyncSingleSelectionField = {
    id: "clinicalFacility",
    extractedText: facility.extractedName || "",
    label: "Facility",
    value: facility.facility,
    onChange: onFacilitySelection,
    type: FieldType.AsyncSelection,
    fetchItems: fetchFacilities,
    required: true,
  }
  return <AsyncSingleSelect field={field} />
}

import React from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { states } from "utilities/address"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"
import * as styles from "./index.module.scss"

export type PatientAddress = {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip?: string
}

const StateSelect = ({
  value,
  onChange,
}: {
  value?: string
  onChange: (value: string) => void
}) => {
  const stateOptions = states.map((state) => ({
    label: state[1],
    value: state[1],
  }))
  const options = [
    { label: "Enter state", value: "item_not_selected", disabled: true },
    ...stateOptions,
  ]

  const defaultValue = stateOptions.some((o) => o.value === value)
    ? value
    : "item_not_selected"
  return (
    <CanopySelectField
      className={styles.state}
      label="State"
      defaultValue={defaultValue}
      options={options}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}
export const ContactInformation = ({
  address,
}: {
  address: PatientAddress
}) => {
  return (
    <>
      <h2 className="canopy-typography-heading-xlarge">Patient info</h2>
      <h3 className="canopy-typography-heading-large">Contact info</h3>
      <div className={styles.addressLineContainer}>
        <CanopyTextInputField
          label="Address - Line 1"
          defaultValue={address.line1}
          onChange={(e) =>
            console.log("Address Line 1 changed:", e.target.value)
          }
          placeholder="Enter address line 1"
        />
        <CanopyTextInputField
          label="Address - Line 2"
          defaultValue={address.line2}
          onChange={(e) =>
            console.log("Address Line 2 changed:", e.target.value)
          }
          placeholder="Enter address line 2"
        />
      </div>
      <div className={styles.cityStateZiplineContainer}>
        <CanopyTextInputField
          className={styles.city}
          label="City"
          defaultValue={address.city}
          onChange={(e) => console.log("City changed:", e.target.value)}
          placeholder="Enter city"
        />
        <StateSelect
          value={address.state}
          onChange={(value) => console.log("State changed:", value)}
        />
        <CanopyTextInputField
          className={styles.zip}
          label="Zip"
          defaultValue={address.zip}
          onChange={(e) => console.log("Zip changed:", e.target.value)}
          placeholder="Enter zip"
        />
      </div>
    </>
  )
}

import React, { useState } from "react"
import {
  CatalogBrowseTab,
  ConsignmentCloset,
  Nullable,
} from "../../../../../../sharedTypes"
import { NestedOptions } from "../CategorySelector/sharedTypes"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Filters from "./Filters"
import Button from "components/form/Button"

type Props = {
  canFilterByFormulary: boolean
  canFilterByYourOrganizationsSuppliers: boolean
  categoryOptions: NestedOptions[]
  combinedCatalogSearch: boolean
  consignmentClosetOnly: boolean
  consignmentClosets: ConsignmentCloset[]
  currentCatalogBrowseTab: CatalogBrowseTab
  defaultConsignmentClosetId: string
  formularyOnly: boolean
  manageFulfillmentAgreements: boolean
  selectCategory: (number: number) => void
  selectDefaultConsignmentCloset: (id: string) => Promise<void>
  selectState: (state: string) => void
  selectedCategory: Nullable<number>
  serviceAreaState: string
  toggleConsignmentClosetFilter: () => void
  toggleFormularyFilter: () => void
  toggleYourOrganizationsSuppliersFilter: () => void
  usesEnterpriseFeatures: boolean
  yourOrganizationsSuppliersOnly: boolean
}

const MobileFilters = ({
  canFilterByFormulary,
  canFilterByYourOrganizationsSuppliers,
  categoryOptions,
  combinedCatalogSearch,
  consignmentClosetOnly,
  consignmentClosets,
  currentCatalogBrowseTab,
  defaultConsignmentClosetId,
  formularyOnly,
  manageFulfillmentAgreements,
  selectCategory,
  selectDefaultConsignmentCloset,
  selectState,
  selectedCategory,
  serviceAreaState,
  toggleConsignmentClosetFilter,
  toggleFormularyFilter,
  toggleYourOrganizationsSuppliersFilter,
  usesEnterpriseFeatures,
  yourOrganizationsSuppliersOnly,
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Button
        className="btn btn-brand-o btn-block"
        onClick={() => setShowModal(true)}
      >
        Filters
      </Button>
      <CanopyDialogModal
        header="Filters"
        open={showModal}
        onClose={() => setShowModal(false)}
        primaryFooterButton={
          <CanopyButton
            className="btn btn-brand btn-block"
            onClick={() => setShowModal(false)}
          >
            Done
          </CanopyButton>
        }
      >
        <Filters
          canFilterByFormulary={canFilterByFormulary}
          canFilterByYourOrganizationsSuppliers={
            canFilterByYourOrganizationsSuppliers
          }
          categoryOptions={categoryOptions}
          combinedCatalogSearch={combinedCatalogSearch}
          consignmentClosetOnly={consignmentClosetOnly}
          consignmentClosets={consignmentClosets}
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          defaultConsignmentClosetId={defaultConsignmentClosetId}
          formularyOnly={formularyOnly}
          isMobile={true}
          manageFulfillmentAgreements={manageFulfillmentAgreements}
          selectCategory={selectCategory}
          selectState={selectState}
          selectedCategory={selectedCategory}
          selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
          serviceAreaState={serviceAreaState}
          toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
          toggleYourOrganizationsSuppliersFilter={
            toggleYourOrganizationsSuppliersFilter
          }
          toggleFormularyFilter={toggleFormularyFilter}
          usesEnterpriseFeatures={usesEnterpriseFeatures}
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      </CanopyDialogModal>
    </>
  )
}

export default MobileFilters

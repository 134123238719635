import React from "react"
import { DmeOrder, Doctor, SignatureRequest } from "sharedTypes"
import { Permissions } from "../../sharedTypes"
import Warning from "./Warning"
import { summarizeSignatureRequests } from "../../utilities/signature"
import { fullName } from "utilities/person"
import SendSignatureReminderButton from "../SendSignatureReminderButton"
import { useFeatureFlags } from "components/FeatureFlagContext"

const displaySignaturePendingWarning = (
  doctor,
  signatureRequests: SignatureRequest[],
  nextExpectedDate: string | undefined,
  permissions: Permissions,
  dmeOrder: DmeOrder,
  sendSignatureRequest: (params: {}) => Promise<void>,
  facilityFreedomProminentRejectionFeatureFlagEnabled: boolean
) => {
  const nextExpectedText = `A signature reminder is scheduled to be sent on ${nextExpectedDate} at 12 pm EST.`

  if (!(signatureRequests.length && doctor)) {
    return (
      <div>
        {nextExpectedDate ? (
          <>
            <div>{nextExpectedText}</div>
            {facilityFreedomProminentRejectionFeatureFlagEnabled &&
              permissions.sendSignatureReminder && (
                <SendSignatureReminderButton
                  sendSignatureRequest={sendSignatureRequest}
                  disabled={dmeOrder.transferredOrderLocked}
                  renderButtonAsLink={true}
                />
              )}
          </>
        ) : (
          "Signature request is being sent."
        )}
      </div>
    )
  }

  const {
    originalRequestsSummary,
    reminderRequestsSummary,
  } = summarizeSignatureRequests(signatureRequests)

  return (
    <div>
      <div>
        Requested from Dr. {fullName(doctor)} {originalRequestsSummary}.
        {nextExpectedDate && " " + nextExpectedText}
      </div>
      {reminderRequestsSummary && <div>Reminded {reminderRequestsSummary}</div>}
      {facilityFreedomProminentRejectionFeatureFlagEnabled &&
        permissions.sendSignatureReminder && (
          <SendSignatureReminderButton
            sendSignatureRequest={sendSignatureRequest}
            disabled={dmeOrder.transferredOrderLocked}
            renderButtonAsLink={true}
          />
        )}
    </div>
  )
}

type Props = {
  doctor: Doctor
  signatureRequests: SignatureRequest[]
  trackEvents: boolean
  warningType: string
  nextExpectedDate: string | undefined
  permissions: Permissions
  dmeOrder: DmeOrder
  sendSignatureRequest: (params: {}) => Promise<void>
}
function SignaturePendingWarning({
  doctor,
  signatureRequests,
  trackEvents,
  warningType,
  nextExpectedDate,
  permissions,
  dmeOrder,
  sendSignatureRequest,
}: Props) {
  const { isFeatureEnabled } = useFeatureFlags()
  const facilityFreedomProminentRejectFeatureFlagEnabled = isFeatureEnabled(
    "facilityFreedomProminentRejection"
  )
  return (
    <Warning
      severity="warning"
      title="Signature Pending"
      warningType={warningType}
      trackEvents={trackEvents}
    >
      {displaySignaturePendingWarning(
        doctor,
        signatureRequests,
        nextExpectedDate,
        permissions,
        dmeOrder,
        sendSignatureRequest,
        facilityFreedomProminentRejectFeatureFlagEnabled
      )}
    </Warning>
  )
}

export default SignaturePendingWarning

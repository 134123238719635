import React, { useEffect, useState } from "react"
import classNames from "classnames"
import * as styles from "./FocusTiles.module.scss"
import Icon from "components/Icon"
import Link from "components/Link"

interface Props {
  getCount: () => Promise<{ count: number }>
  url: string
  selected?: boolean
  title: string
  subtitle: string
}

const FocusTile: React.FC<Props> = ({
  getCount,
  url,
  title,
  selected = false,
  subtitle,
}) => {
  const [loadingCount, setLoadingCount] = useState(true)
  const [count, setCount] = useState<number | undefined>(undefined)

  useEffect(() => {
    getCount().then((data) => {
      setCount(data.count)
      setLoadingCount(false)
    })
  }, [getCount])

  const countComponent = () => {
    if (loadingCount) {
      return (
        <Icon
          className={styles.spinner}
          size="2x"
          spin={true}
          type="spinner"
          ariaLabel="loading count"
        />
      )
    } else {
      return <div className={styles.count}>{count}</div>
    }
  }

  return (
    <Link
      className={classNames(styles.container, selected && styles.selected)}
      href={url}
    >
      <h2 className={styles.title}>{title}</h2>
      {countComponent()}
      <h3 className={styles.subtitle}>{subtitle}</h3>
    </Link>
  )
}

export default FocusTile

import React from "react"
import { Route, Switch } from "react-router-dom"
import { pathMatch } from "./routes"
import * as styles from "./index.module.scss"
import { LLMResult, Patient } from "./sharedTypes"
import { Nullable } from "sharedTypes"
import LLMResultJson from "./components/LLMResultJson"
import { SupplierIntakeNavbar } from "./components/SupplierIntakeNavbar"
import DocumentsBrowser from "components/DocumentsBrowser"
import { Document } from "components/DocumentsBrowser/sharedTypes"
import { withBrowserRouter } from "routers/BrowserRouter"
import { inboxDashboardUrl } from "./urls"
import { DataEntryColumn } from "./components/DataEntryColumn"
import { InboxStateClinicalFacility } from "./components/DataEntryColumn/FacilityData"
import { CsrInboxStateProvider } from "./csrInboxStateContext"
import { PatientAddress } from "./components/DataEntryColumn/ContactInformation"

interface Props {
  patient: Patient
  patientAddress: PatientAddress
  llmResult: Nullable<LLMResult>
  supplier: { id: string }
  id: string
  supplierOrganization: { id: string }
  facility: InboxStateClinicalFacility
  document: Document
}
const SupplierOrganizationInboxDocument = ({
  id,
  facility,
  patient,
  patientAddress,
  supplier,
  supplierOrganization,
  llmResult,
  document,
}: Props) => {
  return (
    <CsrInboxStateProvider
      id={id}
      supplierOrganization={supplierOrganization}
      supplier={supplier}
    >
      <SupplierIntakeNavbar
        dashboardUrl={inboxDashboardUrl(supplierOrganization.id, supplier.id)}
      />
      <div className={styles.columnContainer}>
        <div className={styles.documentColumn}>
          <div className={styles.documentBrowserFrame}>
            <DocumentsBrowser
              newUi
              documents={[document]}
              hideDocumentsNav
              className={styles.documentBrowserInjectedStyle}
            />
          </div>
        </div>
        <div className={styles.dataEntryColumn}>
          <Switch>
            <Route path={pathMatch("/json_blob")}>
              <LLMResultJson llmResult={llmResult} />
            </Route>
            <Route path={pathMatch()}>
              <DataEntryColumn
                facility={facility}
                patient={patient}
                patientAddress={patientAddress}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </CsrInboxStateProvider>
  )
}
export default withBrowserRouter(SupplierOrganizationInboxDocument, {})

// @ts-strict-ignore
import SideBarAwareContainer from "../SideBarAwareContainer"
import React from "react"
import { NestedOptions } from "./sharedTypes"
import { Nullable } from "sharedTypes"
import Select from "./Select"

export type Props = {
  header: string
  options: NestedOptions[]
  selectedValue: Nullable<number>
  select(n: number): void
}

const CategorySelectorMobile: React.FunctionComponent<Props> = ({
  options,
  selectedValue,
  select,
  header,
}) => {
  const isSelected = (option) =>
    option.value === selectedValue ||
    (option.suboptions &&
      option.suboptions.find(
        (childOption) => childOption.value === selectedValue
      ))

  const selectedOption = options.find(isSelected)

  let suboptions
  if (
    selectedOption &&
    selectedOption.suboptions &&
    selectedOption.suboptions.length > 0
  ) {
    suboptions = [
      {
        value: selectedOption.value,
        label: `All ${selectedOption.label}`,
        disabled: false,
      },
    ].concat((selectedOption.suboptions as any[]) || [])
  } else {
    suboptions = []
  }
  const selectedSuboption = suboptions && suboptions.find(isSelected)

  return (
    <div className="row gutter-0">
      <div className="col-12">
        <SideBarAwareContainer role="selectOneMobile">
          <Select
            selectedOption={selectedOption}
            select={select}
            options={options}
            labelText={header}
          />
          {suboptions && suboptions.length > 0 && (
            <Select
              selectedOption={selectedSuboption}
              select={select}
              options={suboptions}
              labelText="Subcategory"
            />
          )}
        </SideBarAwareContainer>
      </div>
    </div>
  )
}

export default CategorySelectorMobile

import FetchingCombobox from "applications/Cms/components/FetchingCombobox"
import React from "react"

export enum FieldType {
  AsyncSelection = "async-selection",
}

type BaseField<T> = {
  id: string
  extractedText: string
  label: string
  value: T | undefined
  onChange: (updatedValue: T | null) => Promise<void>
  type: FieldType
  required: boolean
}

export type AsyncSingleSelectionField = BaseField<{
  id: string
  name: string
}> & {
  type: FieldType.AsyncSelection
  fetchItems: (searchValue: string) => Promise<{ id: string; name: string }[]>
}

export const AsyncSingleSelect: React.FC<{
  field: AsyncSingleSelectionField
}> = ({ field }) => {
  return (
    <FetchingCombobox
      label={field.label}
      fetchItems={field.fetchItems}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      defaultValue={field.value}
      required={field.required}
      onSelection={(newValue) => {
        if (newValue.length > 0) {
          field.onChange(newValue[0])
        } else {
          field.onChange(null)
        }
      }}
      description={`Extracted text: ${field.extractedText}`}
    />
  )
}
